<template>
  <v-menu
    ref="menu"
    v-model="openMenu"
    :nudge-right="40"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        :value="datesFormatted"
        :label="label"
        :append-icon="dates.length ? null : 'event' "
        outlined
        readonly
        clearable
        dense
        :error-messages="errorMessages"
        v-on="on"
        @click:clear=" $emit('resetDate'); openMenu = false; dates = [];"
        @blur="$emit('blur')"
      />
    </template>
    <v-date-picker
      :value="datePickerValue"
      :range="!isSingleDate"
      @input="changeDates"
    />
  </v-menu>
</template>

<script>
import { DateTime } from 'luxon'
import { Timestamp } from '@/services/firebase'

export default {
  name: 'DateRangeField',
  props: {
    value: { type: Object, default: null },
    timezone: { type: String, required: true },
    label: { type: String, default: '' },
    errorMessages: { type: String, default: '' },
    isSingleDate: { type: Boolean, default: false },
  },
  data() {
    return {
      openMenu: false,
      dates: [],
    }
  },
  computed: {
    datesValid: ({ dates }) => dates?.length && dates?.every(date => date?.length === 10),
    singleDate: ({ isSingleDate, dates }) => isSingleDate && dates.length ? dates[0] : '',
    startDate: ({ dates, isSingleDate, singleDate }) => isSingleDate ? singleDate : dates.length && dates[0],
    endDate: ({ dates }) => dates.length && dates[1],
    datesFormatted: ({ datesValid, startDate, endDate, isSingleDate }) => datesValid ? `${DateTime.fromISO(startDate).toLocaleString()}${!isSingleDate ? ` ~ ${DateTime.fromISO(endDate).toLocaleString()}` : ''}` : '',
    datePickerValue: ({ isSingleDate, singleDate, dates }) => isSingleDate ? singleDate : dates?.flat(),
  },
  watch: {
    value(value) {
      if (value) this.dates = this.formatDates()
    },
  },
  created() {
    if (this.value) this.dates = this.formatDates()
  },
  methods: {
    formatDates() {
      const { value: { start, end } } = this
      return [
        ...(start && start instanceof Timestamp ? [DateTime.fromSeconds(start.seconds, { zone: this.timezone }).toISODate()] : []),
        ...(end && end instanceof Timestamp ? [DateTime.fromSeconds(end.seconds, { zone: this.timezone }).toISODate()] : []),
        ...(!end && this.value instanceof Timestamp ? [DateTime.fromSeconds(this.value.seconds, { zone: this.timezone }).toISODate()] : []),
      ]
    },
    changeDates(newValue) {
      if (this.isSingleDate) {
        this.$emit('input', Timestamp.fromDate(DateTime.fromISO(`${newValue}T00:00:00`, { zone: this.timezone }).toJSDate()))
        this.openMenu = false
      } else {
        if (newValue.length === 2) {
          newValue.sort((a, b) => Date.parse(a) - Date.parse(b))
          this.$emit('input', {
            start: Timestamp.fromDate(DateTime.fromISO(`${newValue[0]}T00:00:00`, { zone: this.timezone }).toJSDate()),
            end: Timestamp.fromDate(DateTime.fromISO(`${newValue[1]}T23:59:59`, { zone: this.timezone }).toJSDate()),
          })
          this.openMenu = false
          this.dates = []
        }
        this.dates = newValue
      }
    },
  },
}
</script>
